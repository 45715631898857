import React from "react"
import Layout from "../components/layout"
import Link from "../components/link"

export default function TackPage() {
  return (
    <Layout>
      <div className="h-screen flex flex-col items-center justify-center bg-primary/30 -mt-16">
        <h1>Tack för ditt meddelande!</h1>
        <p>
          <Link to="/" className="underline">
            Tillbaka till startsidan
          </Link>
        </p>
      </div>
    </Layout>
  )
}
